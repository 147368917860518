import React from "react";

export function useStickyState<T = any>(
  defaultValue: T,
  key: string
): [T, (x: T) => void] {
  const [value, setValue] = React.useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);

    try {
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    } catch (e) {
      console.error(
        `E4657: Could not parse sticky state for key ${key}`,
        stickyValue
      );
      window.localStorage.removeItem(key);

      return defaultValue;
    }
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
