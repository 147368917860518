import { Api } from "../Api";
import { Config } from "../Config";
import { cms } from "./cms";
import { dev } from "./dev";

export const funnels = {
  cms: cms,
  dev: dev,
} as {
  [key: string]: {
    config: Config;
    api: Api;
  };
};
