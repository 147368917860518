import React from "react";
// import { Config } from "../config/Config";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Rating from "@material-ui/lab/Rating";
import { ExitToApp, GitHub, StarBorder } from "@material-ui/icons";
import { RouteComponentProps } from "@reach/router";
import { URLTypes } from "./status-list";
import { requireAuth } from "./requireAuth";
import { Api } from "../config/Api";

export const Summary = requireAuth<
  { api: Api; urls: URLTypes; cmsUrl?: string } & RouteComponentProps
>(({ auth, api, urls, cmsUrl }) => {
  // const [feedback, setFeedback] = React.useState<number | null>(null);
  // const [comment, setComment] = React.useState();

  // TODO: Add bookmark
  // TODO: Share by mail
  // TODO: Share by whatsapp
  // TODO: Share by twitter
  // TODO: Analytics for feedback and comments
  // TODO: Show feedback, analytics only once and if cookie banner was accepted
  // TODO: Show star/bookmarks only as accepted

  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h6">Successfully setup project</Typography>
      {cmsUrl && (
        <Button
          fullWidth
          startIcon={<ExitToApp />}
          href={cmsUrl}
          color="primary"
          variant="contained"
        >
          To the CMS
        </Button>
      )}
      {urls.successURL && (
        <Button
          fullWidth
          startIcon={<ExitToApp />}
          href={urls.successURL}
          variant="contained"
          color="primary"
        >
          To the product
        </Button>
      )}
      {urls.prURL && (
        <Button
          fullWidth
          startIcon={<GitHub />}
          href={urls.prURL}
          target="_blank"
        >
          Look at the PR
        </Button>
      )}

      <Button
        style={{ margin: "20px 0 " }}
        startIcon={<StarBorder />}
        onClick={() =>
          api.starRepo({
            owner: `gitlify`,
            repo: `wizard-examples`,
            token: auth.token,
          })
        }
        variant="contained"
      >
        Want to remember us? Star it!
      </Button>

      {/* <Rating
                    name="customized-empty"
                    size="large"
                    value={feedback}
                    onChange={(e, v) => {
                        setFeedback(v);
                    }}
                />

                <TextField
                    multiline
                    fullWidth
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment((e.target as any).value)}
                    placeholder="Any comments?"
                ></TextField> */}
    </div>
  );
});
