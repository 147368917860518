import React from "react";
import { AppWrapper } from "./context";
import { funnels } from "./config/funnel";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { GithubWrapper, noConfigType } from "./components/GithubWrapper";
import { Form } from "./components/Form";

const RedirectToDefaultType: React.FC<
  { owner?: string; repo?: string } & RouteComponentProps
> = ({ owner, repo }) => {
  console.log("Redirect");
  return <Redirect to={`/github/${owner}/${repo}/${noConfigType}/`} noThrow />;
};

const deduceDataFromReferer = (referrer?: string) => {
  console.log(referrer);

  // TODO: deduce correct information from referrer

  return {
    api: `github`,
    owner: `gitlify`,
    repo: `demo`,
    type: noConfigType,
  };
};

const RedirectToReferrer: React.FC<RouteComponentProps> = () => {
  const referrer = document.referrer;

  const { api, owner, repo, type } = deduceDataFromReferer(referrer);

  return <Redirect to={["", api, owner, repo, type, ""].join("/")} noThrow />;
};

export const App: React.FC<{ children?: undefined }> = () => (
  <AppWrapper>
    <Router>
      {Object.entries(funnels).map(([key, { api, config }]) => (
        <Form
          key={key}
          config={config}
          api={api}
          path={`/${key}/*`}
          basePath={`/${key}/`}
        />
      ))}
      <RedirectToDefaultType path="/github/:owner/:repo/" />
      <GithubWrapper path="/github/:owner/:repo/:type/*" />
      <RedirectToReferrer path="/" />
    </Router>
  </AppWrapper>
);
