import { Config } from "../../Config";

type UserData = {
  item?: string;
};

export const config: Config<UserData> = {
  repo: "demo",
  owner: "marcpalm",
  logo:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFElEQVQIW2MMDQ39z8DAwMAIYwAAKgMD/9AXrvgAAAAASUVORK5CYII=",
  schema: {
    title: "Demo",
    type: "object",
    required: [],
    properties: {
      item: {
        type: "string",
        nullable: true,
      },
    },
  },
  listOfFiles: ["src/", "index.js", "package.json"],
};
