import { Api } from "../../Api";

let j = 0;
function resolve<T>(x: T): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    j++;

    setTimeout(() => {
      if (j % 2 === 1) {
        return reject(`E4584: We always reject odd requests in the demo`);
      } else {
        return resolve(x);
      }
    }, 1000);
  });
}
export const api: Api = {
  getSourceContent: (config) =>
    resolve({
      files: config.listOfFiles.map((x) => ({
        path: x,
        content: x,
      })),
      javascript: ``,
    }),
  loginAndGetToken: () =>
    resolve({
      token: "blabla",
      owner: "marc",
    }),
  commitFiles: (_) => resolve({ commitSha: "mocked" }),
  getRepo: ({ repo }) =>
    resolve(repo === "old" ? { default_branch: "main" } : undefined),
  createRepo: (args) => resolve({ default_branch: "main" }),
  createBranch: (args) => resolve({ branch: "gitlify/set_up" }),
  createPR: (args) => resolve({ address: "https://github.com/pr" }),
  writeFiles: (_, __) => resolve({ path: __.path, sha: "1234" }),
  starRepo: () => resolve(undefined),
};
