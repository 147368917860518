import { Config } from "../config/Config";
import { Api } from "../config/Api";
import { getGithubApi } from "./getGithubApi";
import { loginWithGithub } from "../config/auth";
import { loader } from "graphql.macro";
import { createApolloClient } from "./createApolloClient";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

const query = loader("./query/load-tree.gql");

// TODO: Add Github api

type File = {
  name: string;
  path: string;
  object: {
    text?: string;
    entries?: File[];
  };
};

export type FlatFile = {
  path: string;
  content: string;
};

export const recursionTree = (entries: File[] = []): Array<FlatFile> =>
  entries.reduce(
    (acc: FlatFile[], x: File) =>
      x && x.path && x.object
        ? [
            ...acc,
            ...(x.object.text
              ? [
                  {
                    path: x.path,
                    content: x.object.text,
                  },
                ]
              : []),
            ...(x.object.entries ? recursionTree(x.object.entries) : []),
          ]
        : acc,
    []
  );

const getFiles = async ({
  repo,
  owner,
  path,
  apolloClient,
}: {
  repo: string;
  owner: string;
  path?: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
}): Promise<{ path: string; content: string }[]> => {
  const result = await apolloClient.query({
    query,
    variables: {
      repo: repo,
      owner: owner,
      query: `HEAD:${path || ""}`,
    },
  });

  return result.data.repository && result.data.repository.folder
    ? recursionTree(result.data.repository.folder.entries).filter(Boolean)
    : [];
};

export const api: Api = {
  loginAndGetToken: loginWithGithub,
  getRepo: ({ token, owner, repo }) => {
    return getGithubApi({ token })
      .repos.get({ owner, repo })
      .then(
        ({ data: { default_branch } }) => ({ default_branch }),
        (e) => undefined
      );
  },
  createRepo: async ({ token, owner, repo, isPublic }) => {
    const gh = getGithubApi({ token });

    const description = `Created by gitlify.com via ${document.location.href}`;

    const currentlyLoggedIn = await gh.users.getAuthenticated();

    const isOrg = currentlyLoggedIn.data.login !== owner;
    const createPromise = isOrg
      ? gh.repos.createInOrg({
          name: repo,
          org: owner,
          description,
          private: !isPublic,
          auto_init: true,
        })
      : gh.repos.createForAuthenticatedUser({
          name: repo,
          description,
          private: !isPublic,
          auto_init: true,
        });

    return createPromise.then(({ data: { default_branch } }) => ({
      default_branch,
    }));
  },
  createPR: async (
    { token, owner, repo, defaultBranch, targetBranch },
    config
  ) => {
    const gh = getGithubApi({ token });

    const res = await gh.pulls.create({
      owner,
      repo,
      maintainer_can_modify: true,
      // draft: true, not supported everywhere https://docs.github.com/en/free-pro-team@latest/github/collaborating-with-issues-and-pull-requests/about-pull-requests#draft-pull-requests
      title: `[gitlify.com] - wizard for ${config.owner}/${config.repo}"`,
      body: `Created by gitlify.com via ${document.location.href
        .split("/")
        .slice(0, -1)
        .join("/")}`,
      base: defaultBranch,
      head: targetBranch,
    });

    return { address: res.data.html_url };
  },
  createBranch: async ({ token, owner, repo, branch, defaultBranch }) => {
    const gh = getGithubApi({ token });

    const sha = await gh.git
      .getRef({ owner, repo, ref: `heads/${defaultBranch}` })
      .then((x) => x.data.object.sha);

    await gh.git.createRef({ owner, repo, ref: `refs/heads/${branch}`, sha });

    return { branch };
  },
  starRepo: async ({ token, owner, repo }) => {
    await getGithubApi({ token }).activity.starRepoForAuthenticatedUser({
      owner,
      repo,
    });
  },
  getSourceContent: async (config: Config, token: string) => {
    const apolloClient = createApolloClient({ token });

    const { repo, javascriptFile, owner, listOfFiles } = config;

    const sanitizePath = (path: string): string => {
      if (path === ".") {
        return "";
      } else if (path.startsWith("/")) {
        return path.replace("/", "");
      } else if (path.startsWith("./")) {
        return path.replace("./", "");
      } else {
        return path;
      }
    };

    const rawFiles = await Promise.all(
      listOfFiles.map(sanitizePath).map((path: string) =>
        getFiles({
          repo,
          owner,
          path: path,
          apolloClient,
        })
      )
    );

    const javascripts = javascriptFile
      ? await getFiles({
          repo,
          owner,
          path: javascriptFile,
          apolloClient,
        })
      : [];

    if (javascriptFile && javascripts.length !== 1) {
      throw new Error(
        `E4758: Javascript file ${javascriptFile} is either a directory or does not exist.`
      );
    }

    const flatten = function <T>(x: T[][]): T[] {
      return x.reduce((x, y) => [...x, ...y], []);
    };

    const sortBy = function <T>(x: T[], by: (y: T) => string): T[] {
      return x.sort((y, z) => by(y).localeCompare(by(z)));
    };

    const dedupeBy = function <T>(x: T[], by: (y: T) => string): T[] {
      return sortBy<T>(x, by).reduce(
        (acc: T[], y: T) =>
          acc[0] && by(acc[0]) === by(y) ? acc : [y, ...acc],
        []
      );
    };

    const returnValues = {
      files: dedupeBy(flatten(rawFiles), (x) => x.path),
      javascript: javascriptFile ? javascripts[0].content : undefined,
    };

    console.log({ returnValues });

    return returnValues;
  },
  writeFiles: async ({ token, owner, repo, bucketIndex }, file) => {
    return getGithubApi({
      token,
      parallelId:
        bucketIndex === undefined ? undefined : `write-${bucketIndex}`,
    })
      .git.createBlob({
        owner,
        repo,
        content: file.content,
        encoding: "utf-8",
      })
      .then((x) => ({
        sha: x.data.sha,
        path: file.path,
      }));
  },
  commitFiles: async ({ token, owner, repo, files, targetBranch }) => {
    const gh = getGithubApi({ token });

    const shas = await gh.git
      .listMatchingRefs({ owner, repo, ref: `heads/${targetBranch}` })
      .then((x) => x.data);

    if (shas.length === 0) {
      throw new Error(`E4578: Ref for heads/${targetBranch} not found`);
    }

    const sha = shas[0].object.sha;

    const tree = await gh.git.createTree({
      owner,
      repo,
      tree: files.map((file) => ({
        path: file.path,
        mode: "100644",
        type: "blob",
        sha: file.sha,
      })),
      base_tree: sha,
    });

    const commit = await gh.git.createCommit({
      owner,
      repo,
      tree: tree.data.sha,
      message: `feat(gitlify-setup): files added by gitlify.com via ${document.location.href}`,
      parents: [sha],
    });

    await gh.git.updateRef({
      owner,
      repo,
      ref: `heads/${targetBranch}`,
      sha: commit.data.sha,
    });

    return { commitSha: commit.data.sha };
  },
};
