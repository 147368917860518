/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import "./footer.css";

import { ReactComponent as Logo } from "../../logo.svg";
import { ReactComponent as Heart } from "./hearth.svg";

export const Footer: React.FC = () => {
  return (
    <nav className="gf__footer">
      <ul>
        <li>
          <Logo height="12" />{" "}
          <a href="https://www.gitlify.com" target="_blank">
            gitlify.com
          </a>
          <i className="gg-chevron-down"></i>
        </li>
        <li>
          <a href="https://testless.com/imprint" target="_blank">
            Imprint
          </a>
        </li>
        <li>
          <a href="https://www.gitlify.com/legal/privacy/" target="_blank">
            Privacy
          </a>
        </li>
        <li>
          <a href="https://www.gitlify.com/legal/terms/" target="_blank">
            Terms
          </a>
        </li>
        <li>
          <a
            href="https://www.gitlify.com/legal/cookie-policy/"
            target="_blank"
          >
            Cookie Policy
          </a>
        </li>
      </ul>
      <p>
        &copy;&nbsp;{new Date().getFullYear()} Testless&nbsp;GmbH.
        Build&nbsp;with&nbsp;
        <Heart height="10" className="gf__footer__pump" />
        &nbsp;for&nbsp;you. Simplify,&nbsp;trust,&nbsp;focus.
      </p>
    </nav>
  );
};
