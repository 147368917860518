import React from "react";
import { Router, Location } from "@reach/router";
import { TransitionGroup } from "react-transition-group";
import Slide from "@material-ui/core/Slide";

export const FadeTransitionRouter: React.FC = (props) => (
  <div className="app">
    <Location>
      {({ location }) => (
        <TransitionGroup className="transition-group">
          <Slide key={location.key} timeout={500}>
            {/* the only difference between a router animation and
                  any other animation is that you have to pass the
                  location to the router so the old screen renders
                  the "old location" */}
            <Router location={location} className="router">
              {props.children}
            </Router>
          </Slide>
        </TransitionGroup>
      )}
    </Location>
  </div>
);

export const NormalRouter: React.FC<{ basePath: string }> = ({
  basePath,
  children,
}) => <Router basepath={basePath}>{children}</Router>;
