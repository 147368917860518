import React from "react";
import { Auth } from "../config/Config";

type CustomContext<T> = {
  value: T | undefined;
  setValue: (t: T) => void;
};
type Use<T> = [T | undefined, (t: T) => void];

const createContext = function <T>(ns: string) {
  const Ctx = React.createContext<CustomContext<T> | null>(null);

  const ContextProvider: React.FC = ({ children }) => {
    const [value, setValue] = React.useState<T>();

    return <Ctx.Provider value={{ value, setValue }}>{children}</Ctx.Provider>;
  };

  return {
    ContextProvider,
    use: (): Use<T> => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const ctx = React.useContext(Ctx);

      if (ctx === null) {
        throw new Error(
          `E4675: Implementation error - Provider for ${ns} was forgotten`
        );
      }

      return [ctx.value, ctx.setValue];
    },
  };
};

const authContext = createContext<Auth>("auth");
export const AuthContextProvider = authContext.ContextProvider;

export const useAuth = authContext.use;
