import React from "react";
import MuiForm from "../forms/mui";
import Button from "@material-ui/core/Button";
import { RouteComponentProps } from "@reach/router";
import { JSONSchema7 } from "json-schema";

export const OnBoardingForm: React.FC<
  {
    schema: JSONSchema7;
    uiSchema?: any;
    next: () => void;
    setAppData: (x: object | null) => void;
    appData: object | null;
    defaultConfig?: any;
  } & RouteComponentProps
> = ({ defaultConfig, schema, uiSchema, next, setAppData, appData }) => {
  return (
    <div style={{ margin: "0 auto" }}>
      <MuiForm
        formData={appData}
        schema={schema}
        autoComplete="off"
        onChange={(e) => setAppData(e.formData)}
        onSubmit={(_: any) => next()}
        uiSchema={uiSchema}
      >
        <Button type="submit" fullWidth variant="contained" color="primary">
          Submit
        </Button>
        {defaultConfig && (
          <Button
            fullWidth
            onClick={() => {
              setAppData(defaultConfig);
              next();
            }}
          >
            Use defaults
          </Button>
        )}

        <Button
          fullWidth
          onClick={() => {
            setAppData({});
          }}
        >
          Reset
        </Button>
      </MuiForm>
    </div>
  );
};
