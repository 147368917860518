import React from "react";
import {
  makeStyles,
  Theme,
  withStyles,
  hexToRgb,
} from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/Tune";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";

import { ReactComponent as Github } from "./github.svg";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const ColorlibConnector = withStyles((theme: Theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg, ${hexToRgb(
        theme.palette.primary.dark
      )} 0%, ${hexToRgb(theme.palette.primary.main)} 50%, ${hexToRgb(
        theme.palette.primary.light
      )} 100%)`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg, ${hexToRgb(
        theme.palette.primary.dark
      )} 0%, ${hexToRgb(theme.palette.primary.main)} 50%, ${hexToRgb(
        theme.palette.primary.light
      )} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: `linear-gradient( 136deg, ${hexToRgb(
      theme.palette.primary.dark
    )} 0%, ${hexToRgb(theme.palette.primary.main)} 50%, ${hexToRgb(
      theme.palette.primary.light
    )} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 136deg, ${hexToRgb(
      theme.palette.primary.dark
    )} 0%, ${hexToRgb(theme.palette.primary.main)} 50%, ${hexToRgb(
      theme.palette.primary.light
    )} 100%)`,
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <GroupAddIcon />,
    2: <Github height={24} />,
    3: <SettingsIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export const CustomizedSteppers: React.FC<{
  mode?: "dots" | "icon";
  activeStep: number;
  setActiveStep: (n: number) => void;
}> = ({ mode, activeStep, setActiveStep }) => {
  const steps = ["Customize your project", "Choose repo", "Prepare PR"];

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={
        mode === "dots" ? (
          <QontoConnector />
        ) : mode === "icon" ? (
          <ColorlibConnector />
        ) : undefined
      }
      style={{
        zIndex: 1,
      }}
    >
      {steps.map((label, index) => (
        <Step
          key={label}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setActiveStep(index);
          }}
        >
          {!mode && <StepLabel>{label}</StepLabel>}
          {mode === "dots" && (
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          )}
          {mode === "icon" && (
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          )}
        </Step>
      ))}
    </Stepper>
  );
};
