import React from "react";

import { useSnackbar } from "notistack";

import Alert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import Redo from "@material-ui/icons/Redo";

export const ErrorComponent: React.FC<{ errors: any; tryAgain: () => any }> = ({
  errors,
  tryAgain,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (errors) {
      enqueueSnackbar(
        <Alert severity="warning" variant="filled">
          {String(errors)}
        </Alert>,
        {
          key: JSON.stringify(errors),
          variant: "warning",
          action: (key) => (
            <Button
              endIcon={<Redo />}
              onClick={() => {
                tryAgain();
                closeSnackbar(key);
              }}
            >
              Try again
            </Button>
          ),
          preventDuplicate: true,
        }
      );
    } else {
      closeSnackbar();
    }
  }, [errors, tryAgain, enqueueSnackbar, closeSnackbar]);

  return errors ? (
    <Alert severity="warning" variant="filled">
      {String(errors)}
    </Alert>
  ) : null;
};
