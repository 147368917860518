import React from "react";
import "./Form.css";

import { Config } from "../config/Config";
import { Api } from "../config/Api";

import { SplashScreen } from "./SplashScreen";
import Paper from "@material-ui/core/Paper";
import { CustomizedSteppers } from "./stepper";

import { RouteComponentProps, Location } from "@reach/router";
import { Layout } from "./layout";
import { nav, matchIndex } from "./routing";
import { InnerApp } from "./InnerApp";

export const Form: React.FC<
  {
    config: Config;
    api: Api;
    basePath: string;
  } & RouteComponentProps
> = ({ api, config, basePath }) => {
  return (
    <Location>
      {({ location }) => (
        <Layout
          header={
            <CustomizedSteppers
              mode="icon"
              setActiveStep={(x: number) => {
                nav(basePath, x)();
              }}
              activeStep={matchIndex(basePath, location.pathname)}
            />
          }
        >
          <Paper
            style={{ margin: "0 auto", padding: 50 }}
            elevation={6}
            variant="outlined"
          >
            <div style={{ maxWidth: 800 }}>
              <InnerApp
                currentPath={location.pathname}
                api={api}
                config={config}
                basePath={basePath}
              />
            </div>
          </Paper>
        </Layout>
      )}
    </Location>
  );
};

export const AsyncApp: React.FC<
  {
    onConfigChange: (cb: (c: Config) => void) => () => void;
    onApiChange: (config: Config, cb: (api: Api) => void) => () => void;
    basePath: string;
  } & RouteComponentProps
> = ({ onConfigChange, onApiChange, basePath }) => {
  const [config, setConfig] = React.useState<Config | undefined>();
  React.useEffect(() => {
    if (onConfigChange) {
      return onConfigChange((c) => setConfig(c));
    }
  }, [onConfigChange]);

  const [api, setApi] = React.useState<Api | undefined>();
  React.useEffect(() => {
    if (onApiChange && config) {
      return onApiChange(config, (c) => setApi(c));
    }
  }, [config, onApiChange]);

  if (!config || !api) {
    return (
      <Layout>
        <SplashScreen />
      </Layout>
    );
  }

  return <Form api={api} config={config} basePath={basePath} />;
};
