import React from "react";
import MuiForm from "../forms/mui";
import Button from "@material-ui/core/Button";
import { RouteComponentProps } from "@reach/router";
import { requireAuth } from "./requireAuth";
import { Config } from "../config/Config";

export const ChooseRepo = requireAuth<
  {
    back?: () => void;
    next: () => void;
    setRepo: (_: { repo: string; owner: string }) => void;
    repo?: { owner: string; repo: string };
    config: Config;
  } & RouteComponentProps
>(({ back, next, setRepo, auth, repo, config }) => {
  const submit = async (e: { formData?: any }) => {
    next();
  };

  return (
    <div>
      <MuiForm
        formData={repo}
        schema={{
          description:
            "Existing repos will receive a PR with the suggested changes",
          type: "object",
          required: ["repo"],
          properties: {
            repo: {
              title: "Name",
              type: "string",
              default: `gitlify-starter--${config.repo}`,
            },
            // org: {
            //   title: "Organization",
            //   type: "string",
            //   enum: ["marcpalm", "gitlify"],
            //   default: "gitlify",
            // },
          },
        }}
        uiSchema={{
          "ui:button": {
            fullWidth: true,
          },
        }}
        autoComplete="off"
        onChange={(e) => {
          const { repo, owner } = e.formData || {};

          setRepo({
            repo: repo,
            owner: owner ? String(owner) : auth.owner,
          });
        }}
        onSubmit={submit}
      >
        <Button fullWidth type="submit" variant="contained" color="primary">
          submit
        </Button>
        {back && (
          <Button fullWidth onClick={back}>
            Go back
          </Button>
        )}
      </MuiForm>
    </div>
  );
});
