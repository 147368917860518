import React from "react";
import { Config } from "../config/Config";
import { Api } from "../config/Api";
import { ChooseRepo } from "./ChooseRepo";
import { NormalRouter } from "./router";
import { Redirect, navigate } from "@reach/router";
import { Summary } from "./Summary";
import { OnBoardingForm } from "./OnBoardingForm";
import { useStickyState } from "../useStickyState";
import { StatusList, URLTypes } from "./status-list";
import {
  formRoute,
  chooseRepoRoute,
  commitFiles,
  summaryRoute,
} from "./formRoute";

export const InnerApp: React.FC<{
  config: Config;
  api: Api;
  currentPath: string;
  basePath: string;
}> = (props) => {
  const { basePath, api, config } = props;
  const [appData, setAppData] = useStickyState<object | null>(
    null,
    `userData:` + JSON.stringify(config)
  );

  // Please never persist access token
  const [repo, setRepo] = React.useState<{ owner: string; repo: string }>();
  const [branch, setBranch] = React.useState<string>();
  const [urls, setUrls] = useStickyState<URLTypes>(
    {},
    `urlData:` + JSON.stringify(config)
  );

  return (
    <NormalRouter basePath={basePath}>
      {config.schema ? (
        <OnBoardingForm
          path={formRoute}
          next={() => navigate(basePath + chooseRepoRoute)}
          schema={config.schema}
          defaultConfig={config.defaultConfig}
          uiSchema={config.uiSchema}
          appData={appData}
          setAppData={setAppData}
        />
      ) : (
        <Redirect from={formRoute} to={chooseRepoRoute} noThrow />
      )}
      <ChooseRepo
        api={api}
        config={config}
        path={chooseRepoRoute}
        back={config.schema ? () => navigate(basePath + formRoute) : undefined}
        next={() => navigate(basePath + commitFiles)}
        setRepo={setRepo}
        repo={repo}
      />
      <StatusList
        path={commitFiles}
        next={(urls, branch) => {
          setBranch(branch);
          setUrls(urls);
          navigate(basePath + summaryRoute);
        }}
        owner={repo?.owner}
        repo={repo?.repo}
        back={() => navigate(basePath + chooseRepoRoute)}
        api={api}
        config={config}
        userData={appData}
      />
      <Summary
        path={summaryRoute}
        api={props.api}
        urls={urls}
        cmsUrl={
          repo
            ? `https://cms.gitlify.com/github/${repo.owner}/${repo.repo}/${branch}`
            : undefined
        }
      />
      <Redirect
        from="/*"
        to={config.schema ? formRoute : chooseRepoRoute}
        noThrow
      />
    </NormalRouter>
  );
};
