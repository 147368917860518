/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { GitHub } from "@material-ui/icons";
import { Loading } from "./Loading";
import { ErrorComponent } from "./Error";
import { RouteComponentProps } from "@reach/router";
import { Auth } from "../config/Config";
import { useAuth } from "../context/apiContext";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { ReactComponent as Logo } from "../logo.svg";

export const LoginScreen: React.FC<
  {
    getAuth: () => Promise<Auth>;
    next?: () => void;
    back?: () => void;
  } & RouteComponentProps
> = ({ next, back, getAuth }) => {
  const [errors, setErrors] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [, setAuth] = useAuth();

  const handleClick = async () => {
    setLoading(true);
    try {
      await getAuth().then(setAuth);
      if (next) {
        next();
      }
    } catch (e) {
      setErrors(e);
    }
    setLoading(false);
  };

  return (
    <Dialog open={true}>
      <DialogTitle style={{ textAlign: "center", paddingTop: 50 }}>
        <Logo height={40} />
        <Typography>gitlify.com - plug n play!</Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center", padding: "10px 60px 50px" }}>
          <Loading loading={loading}>
            Waiting for the response of Github.
          </Loading>
          <Button
            fullWidth
            startIcon={<GitHub />}
            onClick={handleClick}
            variant="contained"
            color="primary"
          >
            Connect
          </Button>
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography variant="caption" component="p">
            By continuing, you are indicating that you accept our{" "}
            <a href="https://www.gitlify.com/legal/terms/" target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="https://www.gitlify.com/legal/privacy/" target="_blank">
              Privacy Policy
            </a>
            .
          </Typography>
          <br />
          <ErrorComponent errors={errors} tryAgain={handleClick} />
          {back && (
            <Button fullWidth onClick={back}>
              Go back
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
