import React from "react";
import { Footer } from "../footer";
import "./index.css";

// import { Paper, Typography, Link, Button } from '@material-ui/core';
// import { ReactComponent as Logo } from "../../logo.svg";
// import { ArrowRightIcon } from '@primer/octicons-react';

export const Layout: React.FC<{ header?: React.ReactNode }> = ({
  header,
  children,
}) => (
  <div className="gf__layout">
    {header}
    {/* <div style={{ width: "400px", height: "400px", position: "absolute", right: 0, top: 124, zIndex: 0, overflow: "hidden"}}>
          <div style={{ width: "100%",  height: "100%", zIndex: -1, overflow: "hidden", transform: " translate(50%, -50%) rotate(45deg) translate(0, 0%)", display: "flex", textAlign: "center", alignItems: "flex-end" }}>
            <Paper style={{ width: "100%", border: "1px white solid"}}>
              <Button startIcon={<Logo height="12" />} endIcon={<ArrowRightIcon />}>use gitlify on your project</Button>
            </Paper>
          </div>
        </div> */}
    <div style={{ flex: 2, minHeight: 20 }} />
    {children}
    <div style={{ flex: 2 }} />
    <Footer />
  </div>
);
