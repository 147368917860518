import firebase from "@firebase/app";
import { FirebaseAuth, GithubAuthProvider } from "@firebase/auth-types";

// These imports load individual services into the firebase namespace.
import "@firebase/auth";
import { firebaseConfig } from "./firebase-config";
import { Auth as AuthType } from "./Config";

export const Auth = firebase.initializeApp(firebaseConfig, "auth") as {
  auth: () => FirebaseAuth;
};

const getGithubProvider = (scopes: string[]): GithubAuthProvider => {
  const provider = new (firebase.auth as any).GithubAuthProvider();

  provider.setCustomParameters({
    allow_signup: "false",
  });

  provider.addScope("repo");

  scopes.forEach((s) => {
    provider.addScope(s);
  });

  return provider;
};

export const loginWithGithub = (scopes: string[] = []): Promise<AuthType> => {
  const provider = getGithubProvider(scopes);

  return Auth.auth()
    .signInWithPopup(provider)
    .then((data: any) => {
      return {
        token: data.credential.accessToken,
        owner: data.additionalUserInfo.username,
      };
    });
};

export const logout = () => Auth.auth().signOut();
