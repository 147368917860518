import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// window.addEventListener("load", () => {
//   const script = document.createElement('script')

//   script.src = `http://localhost:5000/_v/latest/main.js`
//   script.async = true
//   document.head.appendChild(script)
// })
