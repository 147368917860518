import { Config } from "../../Config";
import { JSONSchema7 } from "json-schema";

import { JSONSchemaType } from "ajv";

const exampleSchemes = ["image", "file", "json", "richtext"] as const;

const types = [
  "string",
  "string:date",
  "string:richtext",
  "string:url",
  "string:data-url",
  "string:email",
  "enum",
  "boolean",
  "number",
  "object",
  "reference:author",
  "reference:atricle",
] as const;

export type CMSData = {
  select?: Array<string>;
  custom?: {
    name: string;
    title?: string;
    description?: string;
    element: Array<{
      key: string;
      type: string;
      multiple: boolean;
    }>;
  };
};

const defaultCustomObject: CMSData["custom"] = {
  name: "custom",
  title: `Custom object`,
  description: `Educational purposes`,
  element: [
    {
      key: `name`,
      type: "string",
      multiple: false,
    },
    {
      key: `emails`,
      type: "string:email",
      multiple: true,
    },
    {
      key: `hobby`,
      type: `enum`,
      multiple: false,
    },
  ],
};

const schema: JSONSchemaType<CMSData> & JSONSchema7 = {
  title: "Play around",
  description: `Choices are not final - you can add, delete, modify later.`,
  type: "object",
  required: [],
  properties: {
    helpText: {
      title: "Static fields",
      description: "We initialize every CMS here with page and author.",
      type: "null",
    },
    select: {
      title: `Choose some more options`,
      type: "array",
      items: {
        type: "string",
        enum: exampleSchemes,
        enumNames: ["Image", "File", "JSON", "Richtext"],
      } as any,
      uniqueItems: true,
      nullable: true,
    },
    custom: {
      title: `Define custom builds`,
      type: `object`,
      required: ["name"],
      nullable: true,
      defaultValue: defaultCustomObject,
      properties: {
        name: {
          type: "string",
          title: `Technical ID`,
        },
        title: {
          nullable: true,
          type: "string",
          description: "You may add a human friendly name (optional)",
          title: `Name`,
        },
        description: {
          nullable: true,
          title: "Description",
          description: "You may add a short description (optional)",
          type: "string",
        },
        element: {
          type: "array",
          title: "Fields",
          items: {
            type: "object",
            required: ["key", "type"],
            description: "What fields do you need?",
            properties: {
              key: {
                title: `Key`,
                description: `The name of the attribute,  e.g. person.name for object person and key name`,
                type: "string",
              },
              multiple: {
                title: "Multiple allowed, i.e. as an array",
                type: `boolean`,
              },
              type: {
                type: "string",
                title: "Some options",
                description: "A gimplse into the options we have",
                enum: types,
                enumNames: [
                  "Arbitrary text",
                  "Date",
                  "Rich text",
                  "URL",
                  "File",
                  "Email",
                  "Enums - for simplicty we generate them",
                  "Boolean",
                  "Number",
                  "Nested Object",
                  "Reference author",
                  "Reference article",
                ],
              } as any, // enumNames are not compliant,
            },
          },
        },
      },
    },
  },
};

const defaultUserData: CMSData = {
  select: (exampleSchemes as unknown) as string[],
  custom: defaultCustomObject,
};

const transformation: Config<CMSData>["transformation"] = (args) => {
  console.log({ args });

  const basepath = `.gitlify`;

  const schemas: { [key: string]: JSONSchema7 } = {
    page: {
      type: "object",
      properties: {
        url: {
          type: `string`,
          format: `url`,
        },
        title: {
          type: `string`,
        },
      },
    },
    author: {
      type: "object",
      properties: {
        name: {
          type: `string`,
        },
        email: {
          type: `string`,
          format: `email`,
        },
        avatar: {
          type: `string`,
          format: `data-url`,
        },
      },
    },
  };

  if (args.userData.select?.includes("image")) {
    schemas.image = {
      type: "object",
      properties: {
        alt: {
          type: `string`,
        },
        src: {
          type: `string`,
          format: `data-url`,
        },
      },
    };
  }

  if (args.userData.select?.includes("file")) {
    schemas.file = {
      type: "object",
      properties: {
        file_name: {
          type: `string`,
        },
        data_url: {
          type: `string`,
          format: `data-url`,
        },
      },
    };
  }

  if (args.userData.select?.includes("json")) {
    schemas.json = {
      type: "object",
      properties: {
        name: {
          type: "string",
        },
        json: {
          type: "string",
          format: "json",
        },
      },
    };
  }

  if (args.userData.select?.includes("richtext")) {
    schemas.json = {
      type: "object",
      properties: {
        name: {
          type: "string",
        },
        richtext: {
          type: "string",
          format: "richtext",
        },
      },
    };
  }

  const customData = args.userData.custom;

  const computeFormat = (x: string, asArray: boolean): JSONSchema7 => {
    if (asArray) {
      return {
        type: "array",
        items: computeFormat(x, false),
      };
    } else if (String(x).startsWith("string")) {
      const [, format] = String(x).split(":");

      return {
        type: "string",
        format,
      };
    } else if (String(x).startsWith("enum")) {
      return {
        type: `string`,
        enum: [`option_1`, `option_2`, `option_3`],
        enumNames: [`Option 1`, `Option 2`, `Option 3`],
      } as any;
    } else if (String(x).startsWith("object")) {
      return {
        type: `object`,
        additionalProperties: {
          type: `string`,
        },
      };
    } else if (x.startsWith(`reference`)) {
      const [, $ref] = String(x).split(":");

      return {
        $ref,
      };
    } else {
      return {
        type: String(x),
      } as any;
    }
  };

  if (customData) {
    schemas[customData.name] = {
      title: customData.title,
      description: customData.description,
      type: "object",
      properties: customData.element.reduce(
        (acc, value) => ({
          ...acc,
          [value.key]: computeFormat(value.type, value.multiple),
        }),
        {}
      ),
    };
  }

  args.resolve({
    targets: [
      ...Object.entries(schemas).map(([key, schema]) => ({
        path: `${basepath}/${key}/schema.json`,
        content: JSON.stringify(schema, null, 2),
      })),
      ...args.files,
    ],
    nextURL: `https://cms.gitlify.com/github/${args.owner}/${args.repo}/branch/${args.prBranch}/`,
  });
};

export const config: Config<CMSData> = {
  repo: "cms-demo",
  owner: "gitlify",
  defaultConfig: defaultUserData,
  schema,
  uiSchema: {
    select: {
      "ui:widget": "checkboxes",
      "ui:options": {
        disabled: true,
      },
    },
  },
  listOfFiles: [""],
  transformation,
};
