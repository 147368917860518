import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "../config/theme";
import { NotificationProvider } from "../components/NotificationProvider";
import { AuthContextProvider } from "./apiContext";

export const AppWrapper: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <CssBaseline />
        <NotificationProvider>{children}</NotificationProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
};
