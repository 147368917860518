import React from "react";
import { Auth } from "../config/Config";
import { Api } from "../config/Api";
import { useAuth } from "../context/apiContext";
import { LoginScreen } from "./LoginScreen";

export function requireAuth<T>(
  R: React.FC<T & { auth: Auth; api: Api }>
): React.FC<T & { api: Api }> {
  return (props) => {
    const [auth] = useAuth();

    if (!auth) {
      return <LoginScreen getAuth={props.api.loginAndGetToken} />;
    }

    return <R auth={auth} {...props} />;
  };
}
