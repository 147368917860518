import { navigate } from "@reach/router";
import {
  formRoute,
  chooseRepoRoute,
  commitFiles,
  summaryRoute,
} from "./formRoute";

export const flow = [
  {
    route: formRoute,
  },
  {
    route: chooseRepoRoute,
  },
  {
    route: commitFiles,
  },
  {
    route: summaryRoute,
  },
];
const joinPaths = (...paths: string[]): string =>
  paths
    .filter(Boolean)
    .reduce((acc, path) => [acc, path].join("/").replace("//", "/"), "");

export const nav = (basePath: string, index: number) => () => {
  const step = flow[index];
  if (step) {
    navigate(joinPaths(basePath, step.route));
  }
};
export const matchIndex = (basePath: string, path: string): number =>
  flow.findIndex((x) => path.startsWith(joinPaths(basePath, x.route)));
