import React from "react";
import { Form } from "./Form";
import { RouteComponentProps } from "@reach/router";
import { Config } from "../config/Config";
import { getGithubApi } from "../github/getGithubApi";
import Ajv, { JSONSchemaType } from "ajv";
import { useAuth } from "../context/apiContext";
import { Octokit } from "@octokit/rest";
import { LoginScreen } from "../components/LoginScreen";
import { loginWithGithub } from "../config/auth";
import { ErrorComponent } from "../components/Error";
import { api } from "../github/api";

type RemoteConfig = {
  globs?: string[];
};

type RemoteConfigSchema = JSONSchemaType<RemoteConfig>;

export const remoteConfigSchema: RemoteConfigSchema = {
  type: "object",
  required: [],
  properties: {
    globs: {
      type: "array",
      nullable: true,
      items: {
        type: "string",
      },
    },
  },
};

export const ajvValidate = function <T>(
  x: any,
  s: JSONSchemaType<T>
): Promise<T> {
  const validate = new Ajv().compile(s);

  const result = validate(x);

  if (result) {
    return Promise.resolve<T>(x as T);
  }

  return Promise.reject(validate.errors);
};

const getJSON = async ({
  owner,
  repo,
  path,
  allowEmpty,
  octokit,
}: {
  owner: string;
  repo: string;
  path: string;
  allowEmpty?: boolean;
  octokit: Octokit;
}) => {
  const content = await octokit.repos.getContent({ owner, repo, path }).then(
    (resp) => (resp.data as { content?: string }).content,
    () => undefined
  );

  if (content) {
    try {
      return JSON.parse(atob(content));
    } catch (e) {
      throw new Error(`E7890: ${path} file not correct json format`);
    }
  } else if (allowEmpty) {
    return undefined;
  } else {
    throw new Error(`E7849: No ${path} file in repo ${owner}/${repo}`);
  }
};

(window as any).Ajv = Ajv;

const RATELIMIT_EXCEEDED = `RATELIMIT_EXCEEDED` as const;

export const noConfigType = "_";

const getConfig = async ({
  owner,
  repo,
  type,
  octokit,
}: {
  owner: string;
  repo: string;
  type: string;
  octokit: Octokit;
}): Promise<Config> => {
  let avatar_url;
  try {
    const response = await octokit.users.getByUsername({ username: owner });

    avatar_url = response.data.avatar_url;
  } catch (e) {
    if (e.headers && e.headers["x-ratelimit-remaining"] === "0") {
      const authState = (await octokit.auth()) as any;

      if (authState.type === `unauthenticated`) {
        return Promise.reject(RATELIMIT_EXCEEDED);
      }
    }

    return Promise.reject(`E7584: No such user ${owner}`);
  }

  const defaultValues = {
    schema: undefined,
    listOfFiles: ["/"],
  };

  const { schema, listOfFiles } =
    type === noConfigType
      ? defaultValues
      : await getJSON({
          repo,
          owner,
          path: `.gitlify/wizard.${type}.json`,
          octokit,
        }).catch((e) => {
          console.error(e);
          return defaultValues;
        });

  const errors = [];

  if (schema) {
    try {
      new Ajv().compile(schema);
    } catch (e) {
      errors.push(
        `E7999: Schema of type ${type} file in repo ${owner}/${repo} is not a valid schema. Please file an issue in github repo ${owner}/${repo}: ${e.message}`
      );
    }
  }

  // TODO: Validate listOfFiles

  return {
    repo,
    owner,
    schema,
    logo: avatar_url,
    listOfFiles: (listOfFiles || ["/"]).map((x: string) =>
      x.endsWith("/") ? x.substr(0, x.length - 1) : x
    ),
  };
};

export const GithubWrapper: React.FC<
  { owner?: string; repo?: string; type?: string } & RouteComponentProps
> = ({ owner, repo, type }) => {
  const [config, setConfig] = React.useState<Config>();
  const [auth] = useAuth();
  const [error, setError] = React.useState<
    typeof RATELIMIT_EXCEEDED | string
  >();
  const [octokit, setOctokit] = React.useState<Octokit>(
    getGithubApi({ token: auth?.token })
  );

  React.useEffect(() => {
    setOctokit(getGithubApi({ token: auth?.token }));
  }, [auth?.token]);

  React.useEffect(() => {
    if (owner && repo && type) {
      getConfig({ owner, repo, type, octokit }).then(
        (x) => setConfig(x),
        (e) => setError(e)
      );
    }
  }, [owner, repo, type, octokit]);

  if (error === RATELIMIT_EXCEEDED) {
    return (
      <LoginScreen getAuth={loginWithGithub} next={() => setError(undefined)} />
    );
  }

  if (error) {
    return (
      <ErrorComponent
        errors={error}
        tryAgain={() => {
          window.location.reload();
        }}
      />
    );
  }

  return config ? (
    <Form
      config={config}
      api={api}
      basePath={[``, `github`, owner, repo, type, ``]
        .filter((x) => x !== undefined)
        .join("/")}
    />
  ) : null;
};
