import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Config, Sources, Target, Transformation } from "../../config/Config";
import { Api } from "../../config/Api";
import Button from "@material-ui/core/Button";
import { CheckCircle, Redo, Warning } from "@material-ui/icons";
import { ErrorComponent } from "../Error";
import { navigate, RouteComponentProps } from "@reach/router";
import { requireAuth } from "../requireAuth";
import { formRoute, chooseRepoRoute } from "../formRoute";

import {
  GitBranchIcon,
  GitCommitIcon,
  GitPullRequestIcon,
  MarkGithubIcon,
  RocketIcon,
  RepoPushIcon,
  SquirrelIcon,
  StarIcon,
  ArrowRightIcon,
  BroadcastIcon,
} from "@primer/octicons-react";

export type RepoData =
  | {
      owner: string;
      default_branch: string;
      repo: string;
      isNew: false;
    }
  | {
      owner: string;
      default_branch: undefined;
      repo: string;
      isNew: true;
    };

const computeBranch = () =>
  `gitlify/set-up-${String(Math.random()).replace("0.", "").substring(0, 5)}`;

const getTargets = (
  sources: Sources,
  args: { userData: object; repo: string; owner: string; prBranch: string },
  transformation?: Transformation<object>
) =>
  new Promise<{ targets: Target[]; nextURL?: string }>(
    async (resolve, reject) => {
      const { javascript, files } = sources || {};
      if (transformation) {
        transformation({
          ...args,
          files,
          resolve,
          reject,
        });
      } else if (javascript) {
        const to = setTimeout(() => {
          reject(`E4783: Neither a js nor files provided`);
        }, 60000);

        const jailed = await import("jailed");

        jailed.DynamicPlugin(String(javascript), {
          ...args,
          files,
          resolve: (x: any) => {
            clearTimeout(to);
            const data = JSON.parse(JSON.stringify(x));
            resolve(data);
          },
          reject,
        });
      } else if (files.length) {
        resolve({
          targets: files.map((x) => ({
            path: x.path,
            content: x.content,
          })),
        });
      } else {
        reject(`E4783: Neither a js nor files provided`);
      }
    }
  );

const requireProps = function <T>(
  R: React.FC<
    T & { owner: string; repo: string; userData: object; config: Config }
  >
): React.FC<T> {
  const RequireOrgAndRepo: React.FC<T> = (props) => {
    const { owner, repo, config, userData, ...remaining } = props as any;

    if (!userData && config.schema) {
      console.log(`E4589: A non trivial schema requires user data.`);
      navigate(formRoute);
      return null;
    }
    if (typeof owner !== `string` || typeof repo !== `string`) {
      console.log(`E4589: Owner and repo required.`);
      navigate(chooseRepoRoute);
      return null;
    }

    return (
      <R
        owner={owner}
        repo={repo}
        userData={userData}
        config={config}
        {...remaining}
      />
    );
  };

  return RequireOrgAndRepo;
};

type State = {
  sources: Sources;
  targets: Target[];
  targetBranch: string;
  defaultBranch: string;
  commitSha: string;
  prAddress: string;
  nextURL?: string;
  writtenFiles: { sha: string; path: string }[];
  index: number;
};

const parallelCount = 20;

export type URLTypes = {
  successURL?: string | undefined;
  prURL?: string | undefined;
};

export const StatusList = requireProps<
  {
    api: Api;
    config: Config;
    userData: object | null;
    owner: string | undefined;
    repo: string | undefined;
    back: () => void;
    next: (
      args: { successURL?: string; prURL?: string },
      branch: string
    ) => void;
  } & RouteComponentProps
>(
  requireAuth(({ api, auth, config, userData, repo, owner, next, back }) => {
    const [state, setState] = React.useState<Partial<State>>({});
    const [isNew, setIsNew] = React.useState<boolean>();
    const [error, setError] = React.useState<boolean>();

    const update = (x: Partial<State>) =>
      setState((state) => ({
        ...state,
        ...x,
      }));

    React.useEffect(() => {
      api
        .getRepo({
          repo: repo,
          token: auth.token,
          owner: owner,
        })
        .then((x) => {
          const defaultBranch = x ? x.default_branch : undefined;
          setIsNew(!defaultBranch);
          update({ defaultBranch });
        });
    }, [api, auth.token, repo, owner]);

    const act = async (
      results: Partial<State> = state,
      recursiveCall: boolean = false
    ): Promise<void> => {
      if (!recursiveCall) {
        update({ index: results.index || 0 });
        setError(undefined);
      }

      const {
        sources,
        targets: allTargets,
        targetBranch,
        defaultBranch,
        commitSha,
        prAddress,
        writtenFiles = [],
      } = results;

      const targets = allTargets;

      // During dev
      // const targets = allTargets ? [allTargets[1]] : undefined

      const updateThis = (
        index: number,
        key: keyof State,
        recursive: boolean = true
      ) => async (value: any) => {
        update({ [key]: value, index }); // Update state for UI
        await new Promise((r) => setTimeout(r, 250));
        if (recursive) {
          return act(
            {
              ...results,
              [key]: value,
              index,
            },
            true
          );
        }
      };

      try {
        if (!sources) {
          return await api
            .getSourceContent(config, auth.token)
            .then(updateThis(1, "sources"));
        } else if (!defaultBranch) {
          return api
            .createRepo({
              repo: repo,
              token: auth.token,
              owner: owner,
            })
            .then((x) => updateThis(2, "defaultBranch")(x.default_branch));
        } else if (!targetBranch) {
          return await api
            .createBranch({
              repo: repo,
              token: auth.token,
              owner: owner,
              branch: computeBranch(),
              defaultBranch,
            })
            .then((x) => updateThis(2, "targetBranch")(x.branch));
        } else if (!targets) {
          return await getTargets(
            sources,
            { userData, repo, owner, prBranch: targetBranch },
            config.transformation
          ).then((x) => {
            const { targets, nextURL } = x;
            update({ nextURL, targets });

            return act({
              ...results,
              index: 3,
              nextURL,
              targets,
            });
            //updateThis(2, "targets")(targets)
          });
        } else if (writtenFiles.length !== targets.length) {
          const remainingallRemainingTargets = targets.filter(
            (x) => writtenFiles.findIndex((y) => y.path === x.path) === -1
          );
          const startIndex = 0;
          let currentIndex = startIndex + parallelCount - 1;

          const starters = remainingallRemainingTargets.slice(
            startIndex,
            currentIndex + 1
          );

          const remainders = remainingallRemainingTargets.slice(
            currentIndex + 1
          );

          const write = (
            fileIn: {
              path: string;
              content: string;
            },
            index: number
          ): Promise<void> => {
            return api
              .writeFiles(
                {
                  token: auth.token,
                  owner: owner,
                  repo: repo,
                  bucketIndex: index,
                },
                fileIn
              )
              .then((file) => {
                writtenFiles.push(file);
                targets.filter((x) => x.path);

                update({ writtenFiles });

                currentIndex = writtenFiles.length;
                const next = remainders.pop();

                if (next) {
                  return write(next, index);
                }
              });
          };

          await Promise.all(starters.map((e, i) => write(e, i)));

          return updateThis(4, "writtenFiles")(writtenFiles);
        } else if (!commitSha) {
          const { commitSha } = await api.commitFiles({
            token: auth.token,
            files: writtenFiles,
            repo: repo,
            owner: owner,
            targetBranch: targetBranch,
          });
          return updateThis(5, "commitSha")(commitSha);
        } else if (!prAddress) {
          const { address } = await api.createPR(
            {
              repo: repo,
              token: auth.token,
              owner: owner,
              defaultBranch: defaultBranch,
              targetBranch: targetBranch,
            },
            config
          );
          return updateThis(6, "prAddress")(address);
        } else {
          await api.starRepo({
            token: auth.token,
            repo: config.repo,
            owner: config.owner,
          });

          return update({ index: 7 });
        }
      } catch (e) {
        setError(e.message || JSON.stringify(e));
      }
    };

    const steps = [
      {
        Icon: MarkGithubIcon,
        title: `Pulling source files`,
        secondaryText: `Downloading template source files from Github`,
      },
      {
        Icon: GitBranchIcon,
        title: isNew
          ? `Creating a new repo`
          : `Creating a branch in ${owner}/${repo}`,
        secondaryText: `Preparing your repository`,
      },
      {
        Icon: SquirrelIcon,
        title: `Applying your choices`,
        secondaryText: `Your inputs are used to customize the files in the PR`,
      },
      {
        Icon: RepoPushIcon,
        title: `Uploading files`,
        secondaryText: `Uploading ${
          state.writtenFiles ? state.writtenFiles.length : "0"
        } of ${
          state.targets ? state.targets.length : "xx"
        } files to your github`,
        value: Math.floor(
          ((state.writtenFiles ? state.writtenFiles.length : 0) /
            (state.targets ? state.targets.length : 1)) *
            100
        ),
        valueBuffer: Math.min(
          Math.floor(
            (((state.writtenFiles ? state.writtenFiles.length : 0) +
              parallelCount) /
              (state.targets ? state.targets.length : 1)) *
              100
          ),
          100
        ),
      },
      {
        Icon: GitCommitIcon,
        title: `Commit files`,
        secondaryText: `Commiting ${
          state.targets ? state.targets.length : "xx"
        } files to your repo`,
      },
      {
        Icon: GitPullRequestIcon,
        title: `Create a PR`,
        secondaryText: `Creating PR in ${owner}/${repo}`,
      },
      {
        Icon: StarIcon,
        title: `Star the template repo`,
        secondaryText: `Give the maintainers some useful feedback`,
      },
    ];

    const thisIndex = state.index || -1;
    const isFinished =
      thisIndex === steps.length && state.prAddress && state.targetBranch;
    const hasStarted = thisIndex !== -1;

    const btnsActive = Boolean(error) || !hasStarted || isFinished;

    const confirmButton = (
      <Button
        variant="contained"
        onClick={
          !isFinished
            ? () => act()
            : () =>
                next(
                  {
                    successURL: state.nextURL,
                    prURL: state.prAddress,
                  },
                  state.targetBranch!
                )
        }
        fullWidth
        color="primary"
        endIcon={
          error ? (
            <Warning />
          ) : !hasStarted ? (
            <RocketIcon />
          ) : isFinished ? (
            <ArrowRightIcon />
          ) : (
            <BroadcastIcon />
          )
        }
        disabled={!btnsActive}
      >
        {!hasStarted
          ? `Execute game plan`
          : isFinished
          ? `Next`
          : error
          ? `Error in step ${(thisIndex || 0) + 1}. Try again!`
          : `Working hard...`}
      </Button>
    );

    return (
      <React.Fragment>
        {confirmButton}
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          dense
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Steps:
            </ListSubheader>
          }
        >
          {steps.map(
            ({ Icon, title, secondaryText, value, valueBuffer }, index) => {
              return (
                <React.Fragment key={title}>
                  <ListItem
                    selected={hasStarted && index === thisIndex}
                    onClick={
                      index === thisIndex && error ? () => act() : () => {}
                    }
                  >
                    <ListItemIcon>
                      <Icon size={24} />
                    </ListItemIcon>
                    <ListItemText primary={title} secondary={secondaryText} />
                    {hasStarted && thisIndex && index < thisIndex ? (
                      <CheckCircle color="primary" />
                    ) : index === thisIndex && error ? (
                      <Redo style={{ color: "yellow" }} />
                    ) : (
                      <span />
                    )}
                  </ListItem>
                  {hasStarted && thisIndex === index && !error && (
                    <LinearProgress
                      variant={valueBuffer ? "buffer" : "indeterminate"}
                      value={value}
                      valueBuffer={valueBuffer}
                    />
                  )}
                </React.Fragment>
              );
            }
          )}
        </List>
        <ErrorComponent errors={error} tryAgain={act} />
        {confirmButton}
        <Button fullWidth onClick={back} disabled={hasStarted}>
          Go Back
        </Button>
      </React.Fragment>
    );
  })
);
